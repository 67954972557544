
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.share-thank-you {
  background-size: contain !important;
  background-repeat: no-repeat;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  padding-top: 160px;
  box-sizing: border-box;

  img {
    width: 100%;
    max-width: 1024px;
    height: auto;
  }
}

.image-wrapper {
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.text {
  //margin: 0 auto;
  width: 50%;
  height: auto;
}
