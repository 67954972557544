
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

input[type='email'],
input[type='tel'] {
  width: 512px;
  padding: 15px;
  font-size: 32px;
  outline: none;
  border: 4px solid #b88404;
  color: black;
  font-family: LouisGlobal2 !important;
  font-weight: bold;
  &::placeholder {
    text-transform: uppercase;
    text-align: center;
    color: #b88404;
  }

  @include sm-mobile {
    margin: 0 15px;
    width: 100%;
    max-width: calc(100vw - 30px);
    box-sizing: border-box;
  }
}

.input-wrapper {
  z-index: 9999;
}

.modal-mask {
  z-index: 9998;
  width: 100%;
  height: 100%;
}

.share-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.default-button {
  background: #b88404;
  color: white;
  font-size: 24px;
  padding: 12px 40px;
  text-transform: uppercase;
  border: none;
  outline: none;
  margin-top: 30px;
  z-index: 9999;
}

.modal-mask {
  font-family: 'brandon-grotesque';
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
