
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.policy {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  a {
    color: inherit;
    display: block;
  }
}
