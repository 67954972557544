
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.card-flip {
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-toggle {
  position: relative;
  margin-bottom: 20px;
  height: 44px;

  .flip-toggle-front,
  .flip-toggle-back {
    position: absolute;
    top: 0;
    left: 50%;
    width: 128px;
    height: auto;
    transform: translateX(-50%);
  }
}
.card-flip-inner {
  position: relative;
  width: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.isFlipped .card-flip-inner {
  transform: rotateY(180deg);
}

.card-flip-front,
.card-flip-back {
  width: 100%;
  backface-visibility: hidden;
}

.card-flip-back {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}
