// Mobile-first responsive breakpoints
$breakpoints: (
  sm: 767px,
  md: 768px,
  lg: 1025x,
  xl: 1200px,
  xxl: 1600px,
) !default;

@mixin sm-mobile {
  @media (max-width: map-get($breakpoints, 'sm')) {
    @content;
  }
}

@mixin md-tablet {
  @media (min-width: map-get($breakpoints, 'md')) {
    @content;
  }
}

@mixin md-tablet-landscape {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin lg-desktop {
  @media (min-width: map-get($breakpoints, 'lg')) {
    @content;
  }
}

@mixin xl-desktop {
  @media (min-width: map-get($breakpoints, 'xl')) {
    @content;
  }
}

@mixin xxl-desktop {
  @media (min-width: map-get($breakpoints, 'xxl')) {
    @content;
  }
}

// Custom sizes
@mixin bp-custom-min($min-width) {
  @media (min-width: $min-width + 'px') {
    @content;
  }
}
