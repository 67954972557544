
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.share-social {
  width: 100%;
  max-height: 145px;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  @include sm-mobile {
    margin: 0;
  }

  .social-icon {
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 96px;
    height: 96px;
    margin: 0 0 20px 0;
    box-sizing: border-box;
    border: none;
    outline: none;

    @include sm-mobile {
      width: 60px;
      height: 60px;
    }

    &:not(:last-of-type) {
      margin-right: 15px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
