
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.share-social {
  width: 100%;
  max-height: 145px;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;

  .qr-code {
    display: none;
  }

  .share-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;

    .social-icon {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 60px;
      height: 60px;
      border: none;
      outline: none;

      @include md-tablet {
        margin-right: 25px;
      }
    }

    @include md-tablet {
      justify-content: flex-start;
    }
  }
}
