
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

@import './assets/scss/_base.scss';
html,
body {
  overscroll-behavior: none;
}
body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
  background-size: cover;
  overflow-x: hidden !important;
  font-family: 'brandon-grotesque';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100%;

  @include sm-mobile {
    overflow: auto;
  }
}

#app {
  box-sizing: border-box;
  font-family: 'brandon-grotesque';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.mode-kiosk {
    overflow: hidden;
  }
}

.kiosk-navigation {
  padding: 30px;
  position: absolute;

  .navigation-carat {
    transform: rotate(90deg);
    height: 12px;
  }

  .navigation-text {
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
  }
}
.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.app-header,
.app-footer {
  text-align: center;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
}
.logo {
  margin-top: 29.13px;
  width: 50px;
  height: 50px;

  &.jordan {
    // margin-top: 0px;
    width: 58.74px;
    height: 54.832px;
  }
}
