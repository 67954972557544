
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.modal-header {
  .icon {
    width: 30px;
    height: 24px;
  }
}

.modal-body {
  .field + .field {
    margin-top: 30px;
  }
  label {
    display: block;
    margin-bottom: 15px;
    font-family: 'Buick Futura ND Book';
  }
  input {
    font-size: 16px;
    font-family: inherit;
    outline: none;
    border: 0px;
    border-bottom: 1px solid #3f3f38;
    width: 100%;
    background: #f3f3f3;
    padding-bottom: 10px;
    &::placeholder {
      font-family: 'Buick Futura ND Book';
    }
  }

  textarea {
    font-family: inherit;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
    outline: none;
    height: 120px;
    width: 100%;
    resize: none;
    border: 1px solid #3f3f38;
    background: #f3f3f3;
    &::placeholder {
      font-family: 'Buick Futura ND Book';
    }
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  height: 100%;
  //padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 30px 30px 22px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  @include md-tablet {
    width: 420px;
  }
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  .button {
    color: inherit;
    font-family: inherit;
    font-size: 16px;
    background: none;
    border: none;
    outline: none;
    width: 100%;
    transition: all 200ms;
    margin-bottom: 8px;
    &::before {
      content: 'send';
    }
    @include md-tablet {
      width: auto;
    }
  }
  .send {
    color: #c9480c;
    border: 1px solid #c9480c;
    padding: 20px 60px;
  }
  .btn-cancel {
    margin-right: 50px;
    &::before {
      content: 'cancel';
    }
  }
  .btn-continue {
    margin: 10px auto 0;
  }
  @include md-tablet {
    display: flex;
    justify-content: space-between;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 300ms;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
