
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.thanks {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  letter-spacing: 2.5px;
  font-family: 'Buick Futura ND';
}
.content {
  font-size: 30px;
  text-align: center;

  &::before {
    content: 'Thank You';
    display: block;
    font-size: 1.75em;
    font-weight: 300;
    text-transform: uppercase;
  }
  &::after {
    content: '';
    text-transform: uppercase;
  }
  .after-thanks {
    display: none;
  }
}
