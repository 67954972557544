
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.kiosk {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;

  .title {
    margin: 30px 0;
    &::before {
      content: 'Select your photo';
      text-align: center;
      text-transform: uppercase;
      font-size: 60px;
      font-weight: 900;
      white-space: pre-wrap;
      display: block;
    }
  }
}

.kiosk-background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -99;
  top: 0;
  left: 0;
  background: transparent;
  display: hidden;
}

.top {
  width: 100%;
  margin: 91px 0 34px;
  h1 {
    margin: 0;
    font-family: 'brandon-grotesque';
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: white;
    letter-spacing: 2.16px;
    font-size: 36px;
    line-height: 52px;

    @include sm-mobile {
      font-size: 30px;
    }

    @include xl-desktop {
      font-size: 60px;
      letter-spacing: 3.6px;
    }
  }
}

.wrapper-scrollable {
  overflow-y: auto;
  height: 475px;
  padding-bottom: 30px;
  box-sizing: border-box;

  @include lg-desktop {
    padding: 0 20px 30px;
  }
}
