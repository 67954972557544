
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.privacy {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  .close {
    cursor: pointer;
    padding: 20px;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;
  }
  .frame {
    flex-grow: 1;
    overflow: auto;
    margin-top: -70px;
    position: relative;
    z-index: 1;
  }
}
