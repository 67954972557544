
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.thank-you {
  @include sm-mobile {
    display: none;
  }
}
