// @import url('https://use.typekit.net/jxq0xsd.css');

html,
body {
  font-family: 'brandon-grotesque';
  width: 100%;
  margin: 0;
  padding: 0;
  font-weight: normal;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}
html {
  height: 100%;
  // overflow-y: scroll;
}
body {
  min-height: 100%;
}
