
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.share-card {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  @include sm-mobile {
    margin-bottom: 30px;
  }
}

.middle {
  padding: 20px;
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'flip .'
    'left right';

  @include sm-mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      'flip'
      'left'
      'right';
    border: none;
  }

  .grid-left {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-right: 20px;
    grid-area: left;

    @include sm-mobile {
      padding-right: 0;
    }
  }
  .grid-right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;

    .title {
      font-size: 24px;
      text-align: center;

      &::before {
        content: 'Select';
        text-transform: uppercase;
        line-height: 1.2;
        font-weight: 900;
        white-space: pre-wrap;
        display: block;
      }
      &::after {
        content: 'one of the following';
        text-transform: uppercase;
        white-space: pre-wrap;
        display: block;
      }
      @include md-tablet {
        font-size: 24px;
        text-align: left;
      }
    }

    @include md-tablet {
      margin-left: 20px;
    }
  }
}

.card-item-failed {
  background-color: darkgray !important;
  width: 100%;
  color: black;
  //height: 100%;
  //max-width: 260px;

  item-image {
    width: 100%;
  }
  .item-image {
    background-color: white !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (16 / 9));
  }

  .item-image::after {
    content: '';
    display: block;
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}
