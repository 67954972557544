
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.carousel {
  flex-grow: 1;
  margin: 0 60px;
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    color: inherit;
    margin-top: -85px;
  }
  .swiper-button-prev {
    left: -40px;
  }
  .swiper-button-next {
    right: -40px;
  }
}
