
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.grid-card {
  margin: 0 30px;

  img {
    display: block;
    max-width: 100%;
  }
  &-qr {
    margin: 15px auto 0;
    width: 115px;
  }
}

.grid-card-landscape {
  margin: 0;
}
