
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.share-thank-you {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  padding-top: 173px;
  box-sizing: border-box;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.thank-you-text {
  width: 100%;
  padding-left: 35%;
  text-transform: uppercase;
  text-align: left;
  font-size: 48px;
  font-family: LouisGlobal2;
  color: white;
  font-weight: bold;
}
