
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

.card-item {
  // background-color: white !important;
  width: 100%;
  // height: 100%;
  max-width: 250px;
  margin: 0 auto;
  item-image {
    width: 100%;
  }
  .item-image {
    position: relative;
    //background-color: white !important;
    background-position: center center !important;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .item-image::before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    padding-bottom: calc(100% / (3 / 4));
  }

  .item-footer {
    width: 100%;
    padding: 0 0 0 13px;
    box-sizing: border-box;
    height: 60px;
    //background-color: white;
    display: flex;
    justify-content: space-between;

    align-items: center;

    .item-description {
      font-size: 14px;
      font-family: 'brandon-grotesque';
      font-weight: 300;
    }

    .item-qr {
      width: 50px;
      height: 50px;

      & > * {
        width: 100%;
        height: auto;
      }
    }
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
}
